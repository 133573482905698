const isProduction = window.location.hostname !== 'localhost' && window.location.hostname !== 'techone.com';

const config = isProduction
  ? {
      //apiUrl: 'http://3.107.23.252'  // Use your EC2 public IP and the correct port
      apiUrl: 'https://techonedemo.bbai.com.au'
    }
  : {
      apiUrl: 'http://localhost:8000'
    };

export default config;