import React, { useState } from 'react';
import axios from 'axios';
import waitingSvg from './images/bean_eater.svg';
import bbLogo from './images/BB_Logo.png';
import techoneLogo from './images/technologyone_logo.png';
import awsLogog from './images/PB_AWS_logo.png';

import config from './config';

const styles = {

  responseContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  imageheader: {
    height: '20px',
    margin: '5px 5px',
    maxWidth: '30%',
    objectFit: 'contain',
  },

  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    boxSizing: 'border-box',
  },

  container: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: '5px',
    fontFamily: 'Arial, sans-serif',
    boxSizing: 'border-box',
  },

  header: {
    color: '#333',
    textAlign: 'center',
  },
  normaltext: {
    textAlign: 'center',
  }
  ,
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    marginBottom: '10px',
  },
  inputFile: {
    display: 'none',
  },
  customButton: {
    backgroundColor: '#3b7be3',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    cursor: 'pointer',
    //width: '45%',
  },
  fileName: {
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
    color: '#666',
    fontStyle: 'italic',
    //width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  message: {
    textAlign: 'center',
    color: '#666',
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
};

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleSubmit = async (e) => {
    setMessage('');
    setResponse(null);
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const url = `${config.apiUrl}/api/v1/analysis`;
    const username = 'testuser';
    const password = 'hashbrowns-yummy-filesystems-94223';

    try {
      setIsLoading(true);
      setMessage('');
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        }
      });
      setMessage('File uploaded successfully');
      const return_data = response.data.analysis;
      console.log(return_data);
      setResponse(return_data);
    } catch (error) {
      setMessage('Error uploading file');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = (content) => {
    const lines = content.split('\n');
    let currentTable = null;
    const elements = [];

    lines.forEach((line, index) => {
      if (line.includes('|')) {
        if (!currentTable) {
          // Start a new table
          currentTable = {
            headers: line.split('|').filter(cell => cell.trim() !== '').map(cell => cell.trim()),
            rows: []
          };
        } else if (!line.includes('---')) {
          // Add row to current table
          currentTable.rows.push(line.split('|').filter(cell => cell.trim() !== '').map(cell => cell.trim()));
        }
      } else {
        // If there is an unfinished table at the end, render it if it's not empty
        if (currentTable && currentTable.rows.length > 0) {
          elements.push(
            <table style={styles.table} key={`table-${elements.length}`} border="1">
              <thead>
                <tr>
                  {currentTable.headers.map((header, i) => <th style={styles.tableCell} key={i}>{header}</th>)}
                </tr>
              </thead>
              <tbody>
                {currentTable.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => <td style={styles.tableCell} key={cellIndex}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          );
          elements.push(<br key={`break-${elements.length}`} />);
        }
        currentTable = null;
      }
    });

    // If there's an unfinished table at the end, render it if it's not empty
    if (currentTable && currentTable.rows.length > 0) {

        elements.push(
        <table style={styles.table} key={`table-${elements.length}`} border="1">
          <thead>
            <tr>
              {currentTable.headers.map((header, i) => <th style={styles.tableCell} key={i}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {currentTable.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => <td style={styles.tableCell} key={cellIndex}>{cell}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      );
      elements.push(<br key={`break-${elements.length}`} />);
    }

    return elements;
  };

  return (
    <div>
      <div style={styles.logoContainer}>
        <img style={styles.imageheader} src={bbLogo} alt="Blackbook.ai" />
        <img style={styles.imageheader} src={techoneLogo} alt="TechnologyOne" />
        <img style={styles.imageheader} src={awsLogog} alt="AWS" />
      </div>

      <div style={styles.container}>

      <h2 style={styles.header}>Welcome to the LLM Contract Parser</h2>
      <p style={styles.normaltext}>Please upload your contract file:</p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.buttonGroup}>
          <input
            type="file"
            onChange={handleFileChange}
            style={styles.inputFile}
            id="file-input"
          />
          <label htmlFor="file-input" style={styles.customButton}>
            Choose File
          </label>

          <button type="submit" style={styles.customButton}>Analyse</button>

          </div>
          {fileName && <div style={styles.fileName} title={fileName}>{fileName}</div>}



      </form>
      {message && <p style={styles.message}>{message}</p>}
      {isLoading && (
        <div style={styles.loadingOverlay}>
          <img width={100} src={waitingSvg} alt="Loading..." />
        </div>
      )}
      {response && (
        <div style={styles.responseContainer}>
          <h3 style={styles.header}>Response Content:</h3>
          {Object.entries(response).map(([queryName, messages], index) => (
            <div key={index}>

                <div key={index+'_0'}>
                  {messages.data[0].content.map((contentItem, contentIndex) => (
                    contentItem.type === 'text' && (
                      <div key={contentIndex}>
                        {renderContent(contentItem.text.value)}
                      </div>
                    )
                  ))}

                </div>

            </div>
          ))}
        </div>
      )}
    </div>

    </div>

  );
};

export default FileUpload;